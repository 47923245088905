<template>
  <div>
    <div :class="receive ? '' : 'py-3'" v-if="!loading">

      <template v-if="!orderHash">
        <div class="w-100 m-auto">
          <i class="fa-4x fa-solid fa-warning p-4 text-warning"></i>
          <p>{{ $t('order_requirements_no_hash_p1') }} </p>
          <p>{{ $t('order_requirements_no_hash_p2')}} <router-link :to="{ name: 'view_my_purchases' }">"{{ $t('view_orders_purchased_heading_copy') }}"</router-link></p>
        </div>
      </template>

      <template v-else>
        <ThreeStepBreadcrumbs v-if="!receive" :steps="breadcrumbs" level="2"/>
        <b-container>
        <b-alert show variant="warning" v-for="(error, index) in errors" v-bind:key="index">{{ error }}</b-alert>
        <div class="row mt-3">
          <h2 v-if="!receive" class="text-center font-weight-bold mb-4 w-100">
            <i class="fa-solid fa-check text-primary"></i>
            {{ $t('payment_successful') }}
          </h2>
        </div>
        <div class=" w-100 m-auto">
          <div :class="receive ? 'p-0' : 'content-box p-3'">
            <div class="d-flex flex-column text-left" v-if="order_description !== false">
              <span class="mb-2 font-weight-bold">{{ $t('order_details') }}</span>
              <p class="text-left mb-2" v-html="this.$sanitize(order_description)"></p>
            </div>
            <div class="d-flex flex-column text-left mb-4">
              <span class="mb-2 font-weight-bold">{{ $t('order_requirements_title') }}</span>
              <p class="text-left mb-2">{{$t('order_requirements_help_message')}}</p>
              <b-form-textarea
                id="textarea"
                v-model="orderDetails"
                :placeholder="$t('order_requirements_input_box_placeholder')"
                rows="7"
              ></b-form-textarea>
            </div>
            <div class="d-flex flex-column text-left">
              <span class="mb-2 font-weight-bold">{{ $t('order_upload_requirements_title') }}</span>
              <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true
                            :awss3="awss3"
                            v-on:vdropzone-removed-file='dropzoneRemoved'
                            v-on:vdropzone-error="dropzoneError"
                            v-on:vdropzone-sending="preFileUpload"
                            v-on:vdropzone-queue-complete="dropzoneUploadComplete()"
                            v-on:vdropzone-s3-upload-error="s3UploadError"
                            v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                            v-bind:class="{ 'border-danger': (asset_validation === true)}"
              >
                <div class="dropzone-custom-content text-muted">
                  <i class="fa-solid fa-cloud-arrow-up"></i>
                  <br/>
                  {{ $t('dropzone_placeholder')}}
                </div>
              </vue-dropzone>
            </div>

            <div class="d-flex flex-column text-left mt-2">
              <em class="inlineHint text-left">{{ $t('upload_advice').join(' ') }}</em>
            </div>
            <div class="d-flex text-left mt-4">
              <b-form-checkbox
                id="checkbox-1"
                v-model="terms"
                name="checkbox-1"
              >
                {{ $t('requirements_terms') }}
              </b-form-checkbox>
            </div>
            <div class="d-flex flex-column flex-sm-row mt-3 mx-auto justify-content-center">
              <div class="mr-0 mr-sm-2 mb-2 mb-sm-0" v-if="!submit">
                <b-button class="order-button btn-primary w-100 m-1" @click="submitRequirements" :disabled="!(terms && orderDetails.length > 0) || fileUploading">{{
                  $t('order_requirements_submit_button') }}
                </b-button>
              </div>
              <div class="mr-0 mr-sm-2" v-else>
                <b-button class="order-button btn-primary w-100">
                  <b-spinner small label="small Spinner"></b-spinner>
                </b-button>
              </div>
              <div class="ml-0 ml-sm-2" v-if="!orderDetails.length > 0 && !receive">
                <b-button class="order-button btn-primary w-100 m-1" :to="{ name: 'confirmation', params: { 'seller_username': this.seller_username }}">{{ $t('skip_requirements') }}</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-container>
      </template>
    </div>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"/>
      <div>{{ $t('loading') }}</div>
  </div>
  </div>
</template>

<script>
import config from '../../config'
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ThreeStepBreadcrumbs from '../../components/Breadcrumbs/ThreeStepBreadcrumbs'
import ApiService from '../../apiService'
import { mapActions, mapGetters } from 'vuex'
import store from '../../store'

export default {
  name: 'OrderRequirements',
  components: {
    ThreeStepBreadcrumbs,
    vueDropzone
  },
  props: {
    receive: {
      type: Boolean,
      required: false,
      default: false
    },
    description: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    hash: null
  },
  mounted () {
    this.getOrder()
  },
  data () {
    return {
      loading: true,
      breadcrumbs: [
        { icon: '1', text: this.$t('order_details_breadcrumbs_step_two') },
        { icon: '2', text: this.$t('order_details_breadcrumbs_step_three') },
        { icon: '3', text: this.$t('order_details_breadcrumbs_step_four') }
      ],
      orderDetails: '',
      fileUploading: false,
      submit: false,
      awss3: {
        signingURL: ApiService.getTokenUrl('requirement'),
        headers: {},
        params: {
          'hash': localStorage.getItem('orderHash') ? localStorage.getItem('orderHash') : this.hash
        },
        sendFileToServer: false
      },
      dropzoneOptions: {
        paramName: 'file',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 500,
        maxFiles: 10,
        autoProcessQueue: true,
        timeout: 0,
        retryChunksLimit: 1,
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        renameFile (file) {
          // eslint-disable-next-line no-return-assign
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        }
      },
      uploading: false,
      asset_validation: false,
      errors: [],
      dropzone: null,
      terms: false,
      attachments: [],
      orderHash: localStorage.getItem('orderHash') ? localStorage.getItem('orderHash') : this.hash,
      seller_username: null,
      order_description: this.description,
      tracking_switch: config.tracking_enabled === 'true'
    }
  },
  destroyed () {
    localStorage.removeItem('orderHash')
  },
  methods: {
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    updateToken () {
      let that = this
      this.fetchNewAccessToken().then(() => {
        this.awss3.headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
        this.loading = false

        // $refs.myVueDropzone isn't in the DOM until loading=false
        setTimeout(function () {
          const maxFiles = that.dropzoneOptions.maxFiles
          that.$refs.myVueDropzone.dropzone.on('addedfile', function () {
            if (this.files.length > maxFiles) {
              this.removeFile(this.files[0])
            }
          })
          that.$refs.myVueDropzone.dropzone.on('maxfilesexceeded', function (file) {
            this.removeFile(file)
          })
        }, 250)
      })
    },
    getOrder () {
      if (this.order_description === false && localStorage.getItem('orderHash')) {
        ApiService.getOrder(localStorage.getItem('orderHash')).then(response => {
          if (config.tracking_enabled === 'true') {
            window.__at_px.fire('purchase', response.data.data.price_total.toFixed(2))
          }
          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            let trackingData = this.getTrackingData()
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'eec.purchase',
              'ecommerce': {
                'currencyCode': 'USD',
                'actionField': {
                  'id': response.data.data.hash,
                  'revenue': response.data.data.price_total,
                  'shipping': 0,
                  'payment_method': this.getPaymentMethod()
                },
                'products': [{
                  'item_name': response.data.data.items[0].gig_title.replace(' ', '-'),
                  'item_id': response.data.data.items[0].hash,
                  'category': trackingData['parent_category'] + '/' + trackingData['sub_category'],
                  'price': response.data.data.price_subtotal,
                  'brand': trackingData['brand'],
                  'country_iso': trackingData['seller_iso'],
                  'member_since': trackingData['seller_date'],
                  'task_review_count': trackingData['review_count'],
                  'task_review_average': trackingData['review_average'],
                  'user_review_count': trackingData['user_review_count'],
                  'user_review_average': trackingData['user_review_average'],
                  'seller_response': trackingData['seller_response'],
                  'seller_acceptance': trackingData['seller_acceptance'],
                  'seller_delivery': trackingData['seller_delivery']
                }]
              }
            })
          }
          store.dispatch('clearTrackingData')

          this.order_description = response.data.data.items[0].gig_description
          this.seller_username = response.data.data.seller_username
          this.updateToken()
          localStorage.removeItem('orderHash')
        }).catch(() => {
        })
      } else {
        this.loading = false
      }
    },
    preFileUpload () {
      this.fileUploading = true
      this.errors = []
    },
    dropzoneAdded () {
      this.asset_validation = false
    },
    dropzoneRemoved (file) {
      let array = this.attachments
      for (let i = 0; i < array.length; i++) {
        let imageName = array[i].split('%2F').pop()
        if (imageName === file.upload.filename) {
          array.splice(i, 1)
          break
        }
      }
      this.asset_validation = this.$refs.myVueDropzone.dropzone.getQueuedFiles().length === 0
    },
    dropzoneUploadComplete () {
      this.fileUploading = false
    },
    dropzoneError (file, errorMessage) {
      this.errors.push(errorMessage)
      this.fileUploading = false
    },
    s3UploadError (errorMessage) {
      this.errors.push(errorMessage)
      this.fileUploading = false
    },
    s3UploadSuccess (s3ObjectLocation) {
      this.attachments.push(s3ObjectLocation)
    },
    submitRequirements () {
      this.error = []
      this.submit = true
      ApiService.postOrderRequirement(this.terms, this.orderHash, this.orderDetails, this.attachments).then(() => {
        this.submit = false
        if (this.receive === true) {
          this.$router.go(this.$router.currentRoute)
        } else {
          this.$router.push({ 'name': 'confirmation', params: { 'seller_username': this.seller_username } })
        }
      }).catch((error) => {
        this.submit = false
        if (Object.keys(error.response.data.errors.children.agree_terms).length > 0) {
          this.errors.push(error.response.data.errors.children.agree_terms.errors[0])
        }
        if (Object.keys(error.response.data.errors.children.message).length > 0) {
          this.errors.push(error.errors.children.message.errors[0])
        }
        if (Object.keys(error.response.data.errors.children.assets).length > 0) {
          this.errors.push(error.response.data.errors.children.assets.errors[0])
        }
        this.uploading = false
      })
    }
  },
  computed: {
    ...mapGetters(['getTrackingData', 'getPaymentMethod'])
  }
}
</script>

<style>
  .dropzone {
    border: 2px dashed #dedede;
    border-radius: 5px;
    background: #f5f5f5;
    overflow: auto;
  }

  .dropzone i {
    font-size: 5rem;
    color: #bbb;
  }

  .dropzone .dz-message {
    color: #999;
    font-weight: 600;
    text-transform: uppercase;
  }

  .dropzone .dz-preview.dz-image-preview {
    background-color: transparent !important;
    float: left;
    margin: 0;
    padding: 8px;
  }

  .dropzone .dz-preview .dz-progress .dz-upload {
    background: #28A745;
  }
  .dropzone .dz-preview .dz-progress {
    border: 1px solid white;
  }
</style>
