<template>
  <div class='credit-card-inputs' :class='{ complete }'>
    <b-row class="mb-3 mb-lg-4">
      <b-col cols="12" lg="3" class="d-flex align-items-lg-center justify-content-lg-end">
        <p class="mb-1 mb-lg-0 h6 text-lg-right">{{$t('cardholder_name')}}</p></b-col>
      <b-col cols="12" lg="9">
        <b-form-input id="card-holder-name" name="card-holder-name" v-model="name"
                      type="text" class="input" @change="emitName"
                      :placeholder="$t('cardholder_name_placeholder')"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-3 mb-lg-4">
      <b-col cols="12" lg="3" class="d-flex align-items-center justify-content-lg-end">
        <p class="mb-1 mb-lg-0 h6">{{$t('card_number')}}</p>
      </b-col>
      <b-col cols="12" lg="9">
        <card-number class='stripe-element card-number stripe-card'
                     ref='cardNumber'
                     :stripe='stripe'
                     :options='options'
                     @change='number = $event.complete'
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="3" class="d-flex align-items-center justify-content-lg-end">
        <p class="mb-1 mb-lg-0 h6">{{ $t('expiry_date') }}</p>
      </b-col>
      <b-col cols="12" lg="2">
        <card-expiry class='stripe-element card-expiry stripe-card'
                     ref='cardExpiry'
                     :stripe='stripe'
                     :options='options'
                     @change='expiry = $event.complete'
        />
      </b-col>
      <b-col  cols="12" lg="1" class="d-flex align-items-center justify-content-start justify-content-lg-end mt-3 mt-lg-0">
        <p class="mb-1 mb-lg-0 h6">{{ $t('cvc') }}</p>
      </b-col>
      <b-col cols="12" lg="2" >
          <card-cvc class='stripe-element card-cvc stripe-card'
                    ref='cardCvc'
                    :stripe='stripe'
                    :options='options'
                    @change='cvc = $event.complete'
          />
      </b-col>
      <div class="d-flex align-items-lg-center justify-content-lg-end mt-3 mt-lg-0 pl-3 pl-lg-3 pr-lg-3">
        <p class="mb-1 mb-lg-0 h6">{{ $tc('post_code', displayPostCode) }}</p>
      </div>
      <b-col cols="12" lg="2">
          <postal-code class='stripe-element postal-code stripe-card'
                  ref='postalCode'
                  :stripe='stripe'
                  :options='options'
                  @change='postalCode = $event.complete'
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { CardNumber, CardExpiry, CardCvc, PostalCode } from 'vue-stripe-elements-plus'

export default {
  props: [ 'stripe', 'options' ],
  data () {
    return {
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      postalCode: false,
      name: ''
    }
  },
  components: { CardNumber, CardExpiry, CardCvc, PostalCode },
  methods: {
    emitName () {
      this.$emit('emitName', this.name)
    },
    update () {
      this.complete = this.number && this.expiry && this.cvc && this.postalCode

      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus()
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus()
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus()
        } else if (!this.number) {
          this.$refs.cardNumber.focus()
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    }
  },
  watch: {
    number () { this.update() },
    expiry () { this.update() },
    cvc () { this.update() },
    postalCode () { this.update() }
  },
  computed: {
    displayPostCode: () => {
      return navigator.language.toLowerCase() === 'en-gb' ? 1 : 0
    }
  }
}
</script>

<style lang="scss">
  .stripe-card {
    border: $input-border;
    padding: 0.6rem 0.75rem;
    border-radius: 0.25rem;
    height: 38px;
  }
</style>
