<template>
  <div class="container-fluid mt-1" v-if="loaded">
    <div class="alert alert-danger" role="alert" v-if="hasError">
      {{ error }}
    </div>
    <div class="alert alert-info" role="alert" v-if="hasUserBalance">
      {{ $t('giftcard_balance') }} <strong>${{ balance.amount }}</strong> <br />
      {{ $tc('giftcard_expires', balance.total )}} <strong>{{ balance.expired.toLocaleDateString() }}</strong>
    </div>
    <div class="row">
      <div class="col mb-3 mb-lg-0 d-flex align-items-center">
        <p class="font-weight-bold text-left m-0">
          {{ $t('giftcard_code') }}
        </p>
      </div>
      <div class="col-12 col-lg-6 mb-3 mb-lg-0">
        <b-form-input id="gift-card-code" name="gift-card" v-model="code" type="text"/>
      </div>
      <div class="col">
        <b-button class="order-button w-100" @click="submit" variant="outline-primary" v-if="!submitted" >{{ $t('giftcard_submit') }}</b-button>
        <b-button class="order-button w-100" variant="outline-primary" disabled="disabled" v-else>
          <b-spinner class="" small label="Large Spinner"></b-spinner>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import helper from '../../helper'

export default {
  name: 'GiftCard',
  props: {
    total: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      submitted: false,
      error: null,
      code: '',
      balance: {
        amount: 0.00,
        expired: null,
        total: 0
      }
    }
  },
  created () {
    ApiService.getUserBalance().then(response => {
      if (response.status === 200) {
        const data = response.data.data
        this.setBalance(data.amount, data.date_expired, data.total)
        this.applyDiscount('giftcard')
      }
    }).catch(() => {})
      .finally(() => {
        this.loaded = true
      })
  },
  methods: {
    submit () {
      if (this.code === '' || !helper.luhnCheckDigit(this.code)) {
        this.error = this.$t('giftcard_missing')
      } else {
        this.submitted = true
        ApiService.checkGiftCard(this.code).then(response => {
          const data = response.data.data
          this.setBalance(data.amount, data.date_expired, data.total)
          this.applyDiscount()
        }).catch((error) => {
          this.error = error.response.data.error_message
        }).finally(() => {
          this.submitted = false
        })
      }
    },
    setBalance (amount, expired, total) {
      this.balance.amount = amount.toFixed(2)
      this.balance.expired = new Date(expired)
      this.balance.total = total
    },
    applyDiscount (displayPayment = null) {
      this.$emit('update-balance', this.balance)
      if (this.balance.amount >= this.total) {
        this.$emit('update-payment', this.balance.amount)
        this.$emit('discount', this.$t('giftcard_applied'), this.total, 'stripe', displayPayment)
      } else if (this.hasUserBalance) {
        this.error = this.$t('giftcard_error_insufficient')
      }
    }
  },
  computed: {
    hasUserBalance () {
      if (this.balance.amount !== null) {
        const today = new Date()
        return today <= this.balance.expired
      }
      return false
    },
    hasError () {
      return this.error !== null
    }
  }
}
</script>
