<template>
  <div>{{ display }}</div>
</template>

<script>
export default {
  name: 'Counter',
  props: {
    minutes: {
      type: Number,
      default: 5
    },
    autostart: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      timer: 0,
      display: (this.minutes < 10 ? '0' + this.minutes : this.minutes) + ':00'
    }
  },
  methods: {
    countDown () {
      let minutes = parseInt(this.timer / 60, 10)
      let seconds = parseInt(this.timer % 60, 10)

      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds

      this.display = minutes + ':' + seconds

      if (--this.timer < 0) {
        this.stop()
      }
    },
    start () {
      this.timer = (this.minutes * 60)
      this.countDown()
      this.interval = setInterval(this.countDown, 1000)
    },
    stop () {
      if (this.interval) {
        clearInterval(this.interval)
      }
    },
    restart () {
      this.stop()
      this.start()
    }
  },
  created () {
    if (this.autostart) {
      this.start()
    }
  },
  destroyed () {
    this.stop()
  }
}
</script>
