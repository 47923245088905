<template>
  <div>
    <div class="py-3">
      <ThreeStepBreadcrumbs :steps="getBreadCrumbs" level="3"/>
      <b-container class="mt-5">
        <!-- Task-info -->
        <div class=" w-100 m-auto ">
          <div class="content-box p-3" style="">
            <i class="fa-solid fa-circle-check fa-5x mt-3 mb-3 text-primary" aria-hidden="true"></i>
            <h3 class="p-3 font-weight-bold mb-0">{{ $t('purchase_confirm_p1', { 'seller_username': username }) }}</h3>
            <p>{{ $t('purchase_confirm_p2') }}</p>
            <div class="mt-3 mb-3">
              <b-button v-if="jobHash" class="order-button btn-outline-primary bg-white w-sm-50 mr-2" @click="$router.push({ name: 'jobs_view', params: { hash: jobHash }})">{{ $t('job.card.button.view_job') }}</b-button>
              <b-button class="order-button btn-primary w-sm-50" @click="$router.push('/my-account/orders')">{{ $t('view_orders_button') }}</b-button>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import ThreeStepBreadcrumbs from '../../components/Breadcrumbs/ThreeStepBreadcrumbs'

export default {
  name: 'OrderRequirements',
  components: {
    ThreeStepBreadcrumbs
  },
  props: {
    seller_username: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      username: this.seller_username,
      jobHash: null
    }
  },
  mounted () {
    if (sessionStorage.getItem('jobHash')) {
      this.jobHash = sessionStorage.getItem('jobHash')
      sessionStorage.removeItem('jobHash')
    }
  },
  computed: {
    getBreadCrumbs () {
      if (localStorage.getItem('orderHash')) {
        return [
          { icon: '1', text: this.$t('order_details_breadcrumbs_step_two') },
          { icon: '2', text: this.$t('order_details_breadcrumbs_step_four') }
        ]
      } else {
        return [
          { icon: '1', text: this.$t('order_details_breadcrumbs_step_two') },
          { icon: '2', text: this.$t('order_details_breadcrumbs_step_three') },
          { icon: '3', text: this.$t('order_details_breadcrumbs_step_four') }
        ]
      }
    }
  }
}
</script>
