<template>
    <div class="checkout-breadcrumbs w-100 my-2">
        <b-container>
          <div class="dotted line-style"></div>
            <b-row>
                <div class="align-text-top w-100 py-2">
                    <b-col cols="3" class="step d-inline-block text-center h-100 align-text-top px-1" v-for="(step, index) in steps" :key="index">
                        <div :class="{ selected: index < level }" class="step-icon mx-auto mb-1 d-flex justify-content-center align-items-center font-weight-bold">{{ step.icon }}</div>
                        <div :class="{ selected: index < level }" class="step-text bv-d-sm-down-none small">{{ step.text }}</div>
                    </b-col>
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
  name: 'CheckoutBreadcrumbs',
  props: {
    'steps': {
      type: Array,
      required: true
    },
    'level': {
      type: String,
      required: false,
      default () {
        return '1'
      }
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
    .checkout-breadcrumbs {
      overflow-y: auto !important;
      .step:nth-child(1) {
        .step-icon::after {
          width: 0;
          height: 0;
        }
      }
      .step-icon {
        background-color: $breadcrumbs-grey;
        color: $background-grey;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        z-index: 1;
        &::after {
          z-index: 0;
          content: "";
          width: 58%;
          left: -29%;
          height: 2px;
          position: absolute;
          background: $breadcrumbs-grey;
        }
      }
      @include sm-up {
        .step-icon::after {
          width: 74%;
          left: -37%;
        }
      }
      @include sm-up {
        .step-icon::after {
          width: 80%;
          left: -40%;
        }
      }
      @include lg-up {
        .step-icon::after {
          width: 84%;
          left: -42%;
        }
      }
      @include xl-up {
        .step-icon::after {
          width: 86%;
          left: -43%;
        }
      }
    }
    .step-text {
      color: $breadcrumbs-grey;
      &.selected {
          color: $brand-sky;
      }
    }
    .step-icon {
      &.selected {
        background-color: $brand-sky;
        &::after {
          background: $brand-sky;
        }
      }
    }

    .step-text
    .line-style {
      position: relative;
      top: 27px;
      width: 79%;
      height: 1px;
      margin: 0px auto;
      border-bottom: 2px solid $breadcrumbs-grey;
    }

</style>
