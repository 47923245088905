<template>
  <div>
    <div v-if="showPage" class="py-3">
      <ThreeStepBreadcrumbs :steps="breadcrumbs" level="1"/>
      <b-container fluid class="mt-3">
        <div class="alert alert-danger" role="alert" v-if="errorSwitch">
          {{ errorMessage }}
        </div>
        <div class="row">
          <h2 class="text-center font-weight-bold mb-4 w-100">{{ $t('how_to_pay') }}</h2>
        </div>
        <div class="row" v-if="loadStripe">
          <b-col cols="12" class="col-md-6 col-lg-8 pr-md-2">
            <!-- task info -->
            <div class="bg-white border rounded p-3 mb-3">
              <div class="d-flex flex-row">
                <div class="col-12 col-lg-4 align-top px-0">
                  <div class="assets">
                    <img :src="getImageFromTask()" class="rounded w-100"/>
                  </div>
                </div>
                <div class="col-lg-8 d-inline-flex flex-column p-0 pl-lg-3 checkout-details">
                  <h3 class="mt-3 mt-lg-0 font-weight-bold text-left">{{ task.title }}</h3>
                  <div class="small text-left mb-2" style="overflow-y: auto; max-height: 135px;" v-html="this.$sanitize(task.description, { allowedTags: ['br', 'strong', 'p', 'ul', 'ol', 'li', 'u', 'em', 'i'] })"></div>
                  <div class="row border rounded text-left m-0 pt-1 checkout-details-selection">
                    <div class="col-12 col-sm-6 col-md-12 col-lg-6 pt-2 pb-2">
                      <div class="pb-2 d-flex d-sm-block justify-content-between">
                        <strong>{{ $t('delivery_time_heading') }}:</strong> {{ deliveryTimeString }}
                      </div>
                      <div class="mt-1 d-flex d-sm-block justify-content-between">
                        <strong>{{ $t('revisions_heading') }}:</strong> {{ revisions }}
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-12 col-lg-6 pt-1 pt-sm-2 pt-md-1 pt-lg-2 pb-2">
                      <div class="pb-2">
                        <span class="d-flex d-sm-block justify-content-between"><strong>{{ $t('price_heading') }}:</strong> &dollar;{{ task.packages[0].price.toFixed(2) }}<small v-if="getCurrency() !== 'USD'"> ({{ getDisplayPrice(task.packages[0].price) }}*)</small></span>
                      </div>
                      <div class="d-flex d-sm-block justify-content-between">
                        <strong>{{ $t('quantity') }}:</strong> {{ quantitySelected }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- payment options-->
            <div role="tablist" class="payment-options">

              <!-- card payment -->
              <b-card no-body class="mb-0">
                <b-card-header header-tag="header" class="p-0 bg-white" role="tab" @click="removeGiftCard('stripe'); selectPaymentHash = 'stripe'">
                  <div v-b-toggle.accordion-1 class="p-3 d-flex flex-row align-items-center" v-lazy-container="{ selector: 'img' }">
                    <img :data-src="require('@/assets/CheckoutOrder/card.svg')" :class="{ 'payment-active': selectPaymentHash === 'stripe' }">
                    <h4 class="text-center text-sm-left pl-3 mb-0 font-weight-bold payment-type">{{ $t('credit_debit_card') }}</h4>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-1" :visible="selectedPayment === 'stripe'" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row row-featured row-featured-category ">
                      <div class="container-fluid">
                        <stripe-payment-input :stripe="paymentAuthHash" :options="stripeOptions" v-on:emitName="emitName"/>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- ETN payment -->
              <b-card id="etnSection" no-body class="mb-0" v-if="loadETN">
                <b-card-header header-tag="header" class="p-0 bg-white" role="tab" v-on:click="applyETN(); selectPaymentHash = 'etn'">
                  <div v-b-toggle.accordion-2 class="p-3 d-flex flex-row align-items-center" v-lazy-container="{ selector: 'img' }">
                    <img :data-src="require('@/assets/CheckoutOrder/etn.svg')" :class="{ 'payment-active': selectPaymentHash === 'etn' }">
                    <h4 class="text-center text-sm-left pl-3 mb-0 font-weight-bold payment-type">{{ $t('etn_payment.title') }}</h4>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="container-fluid">
                        <div class="alert alert-danger" role="alert" v-if="errorSwitchETN">
                          {{ errorMessageETN }}
                          <br>
                        </div>
                        <Etn v-if="loadETNCom && !errorSwitchETN && paymentChanged" v-on:show-etn-price="showEtnPrice" v-on:send-payment="pay"/>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Crypto -->
              <b-card no-body class="mb-0" v-if="loadCrypto">
                <b-card-header header-tag="header" class="p-0 bg-white" role="tab" v-on:click="applyCrypto(); selectPaymentHash = 'crypto'">
                  <div v-b-toggle.accordion-3 class="p-3 d-flex flex-row align-items-center" v-lazy-container="{ selector: 'img' }">
                    <img :data-src="require('@/assets/CheckoutOrder/crypto.svg')" :class="{ 'payment-active': selectPaymentHash === 'crypto' }">
                    <h4 class="text-center text-sm-left pl-3 mb-0 font-weight-bold payment-type">{{ $t('payments.crypto.title') }}</h4>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <Crypto ref="paymentCrypto" :shopping-basket-hash="this.$route.params.basket" v-on:next-page="nextPage"  v-on:open-payment="pay"/>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- GiftCard payment -->
              <b-card no-body class="mb-0" v-if="loadGiftCard">
                <b-card-header header-tag="header" class="p-0 bg-white" role="tab" v-on:click="giftCardOptionSelect(); selectPaymentHash = 'giftcard'">
                  <div v-b-toggle.accordion-4 class="p-3 d-flex flex-row align-items-center" v-lazy-container="{ selector: 'img' }">
                    <img :data-src="require('@/assets/CheckoutOrder/gift-card.svg')" :class="{ 'payment-active': selectPaymentHash === 'giftcard' }">
                    <h4 class="text-center text-sm-left pl-3 mb-0 font-weight-bold payment-type">{{ $t('giftcard_title') }}</h4>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" :visible="selectedPayment === 'giftcard'" role="tabpanel">
                  <b-card-body>
                    <GiftCard :total="total" v-on:update-payment="applyGiftCard" v-on:update-balance="updateUserBalance"/>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-col>

          <!--Checkout summary-->
          <b-col cols="12" class="col-md-6 col-lg-4 mt-3 mt-md-0 pl-md-2" id="checkout-summary">
            <div class="checkout-summary">
              <div class="border text-left bg-white p-3 mb-3" v-if="!applyingGiftCard">
                <h3 class="text-left font-weight-bold mb-4">{{ $t('checkout_summary_heading') }}</h3>
                <!-- subtotal -->
                <div class="d-flex justify-content-between mb-2">
                  <div>{{ $t('checkout_summary_subtotal') }}</div>
                  <div class="d-flex align-items-center">
                    <div>&dollar;{{ parseFloat(Math.round(subTotal * 100) / 100).toFixed(2) }}</div>
                    <small v-if="getCurrency() !== 'USD'" class="d-block ml-2">({{ getDisplayPrice(parseFloat(Math.round(subTotal * 100) / 100)) }}*)</small>
                  </div>
                </div>
                <!-- service fee -->
                <div class="d-flex justify-content-between">
                  <div>{{ $t('checkout_summary_service_fee') }} <i class="fa-solid fa-circle-info" v-b-popover.hover.top="$t('service_fee')" aria-hidden="true"></i></div>
                  <div class="d-flex align-items-center">
                    <div>&dollar;{{ parseFloat(Math.round(serviceFee * 100) / 100).toFixed(2) }}</div>
                    <small v-if="getCurrency() !== 'USD'" class="d-block ml-2">({{ getDisplayPrice(parseFloat(Math.round(serviceFee * 100) / 100)) }}*)</small>
                  </div>
                </div>
                <!-- giftcard -->
                <div v-if="showGiftApplied" class="d-flex justify-content-between mt-2">
                  <div>{{ $t('giftcard_applied') }} <i class="fa-solid fa-circle-xmark hover cursor-pointer" aria-hidden="true" @click="removeGiftCard()"></i></div>
                  <div>-&dollar;{{ parseFloat(Math.round(total * 100) / 100).toFixed(2) }}</div>
                </div>
                <hr/>
                <!-- total -->
                <div class="d-flex justify-content-between mb-0">
                  <div>{{ $t('checkout_summary_total') }}</div>
                  <div class="d-flex align-items-center">
                    <div>&dollar;{{ showGiftApplied ? 0.00 : parseFloat(Math.round(total * 100) / 100).toFixed(2) }}</div>
                    <small v-if="getCurrency() !== 'USD'" class="d-block ml-2">({{ getDisplayPrice(parseFloat(Math.round(total * 100) / 100)) }}*)</small>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-2" v-if="showEtn">
                  <div>{{ $t('checkout_summary_etn_total') }}</div>
                  <div class="font-weight-bold">{{ etn_price }} ETN</div>
                </div>
                <template v-if="getCurrency() !== 'USD'">
                  <div class="d-flex justify-content-center usd-billing mt-3">
                    {{ $t('checkout_summary_usd') }}
                  </div>
                  <div class="d-flex justify-content-center usd-billing">
                    {{ $t('checkout_summary_estimated')}}
                  </div>
                </template>
              </div>
              <div v-else class="summary bg-white mt-1 mt-sm-0 pt-2 px-0 border">
                <b-spinner class="m-5" small label="Large Spinner"></b-spinner>
              </div>
              <!-- messaging -->
              <div class="my-4">
                <div class="text-primary mb-2 mt-3">
                  <i class="mr-1 fa-solid fa-shield fa-lg" aria-hidden="true"></i>
                  {{ $t('home.title.guarantee') }}
                </div>
                <div class="text-primary" v-if="selectedPayment === 'stripe'">
                  <i class="mr-1 fa-solid fa-lock fa-lg" aria-hidden="true"></i>
                  {{ $t('secure_payment_message_stripe') }}
                  <img src="../../assets/Logos/stripe_purple.svg" style="width: 65px; margin-left: -4px; padding-bottom: 2px;">
                </div>
                <div class="text-primary" v-if="displaySecureMessage && selectedPayment !== 'stripe'">
                  <i class="mr-1 fa-solid fa-lock fa-lg" aria-hidden="true"></i>
                  {{ $t('secure_payment_message') }}
                </div>
                <div class="text-primary" v-if="selectedPayment === 'etn' || selectedPayment === 'crypto'">
                  <i class="mr-1 fa-solid fa-circle-info fa-lg" aria-hidden="true"></i>
                  {{ $t('blockchain_fee') }}
                </div>
                <div class="text-primary mt-2" v-if="selectedPayment === 'crypto'">
                  <i class="mr-1 fa-solid fa-circle-info fa-lg" aria-hidden="true"></i>
                  {{ $t('payments.crypto.billing.note') }}
                </div>
              </div>

              <!-- Buttons -->
              <div v-if="!buttonDisabled">
                <b-button v-if="loadStripe || loadGiftCard" class="order-button mt-2 w-100 mx-auto" variant="primary" @click='pay(activePaymentHash)'
                          :disabled="applyingGiftCard || selectedPayment === 'etn'" block>{{ $t('pay_button') }}
                </b-button>
                <b-button v-if="loadDummyPayment" class="order-button mt-2 w-100 mx-auto" variant="primary" @click='pay("dummy")' block>By-Pass Payment</b-button>
              </div>
              <div v-else>
                <b-button type="submit" class="order-button mt-2 w-100" variant="primary" disabled="disabled">
                  <b-spinner class="" small label="Large Spinner"></b-spinner>
                </b-button>
              </div>
            </div>
          </b-col>
        </div>
      </b-container>
    </div>
    <!-- loader -->
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading') }}</div>
    </div>
  </div>
</template>

<script>
import { handleCardPayment } from 'vue-stripe-elements-plus'
import ApiService from '../../apiService.js'
import ThreeStepBreadcrumbs from '../../components/Breadcrumbs/ThreeStepBreadcrumbs'
import StripePaymentInput from '../../components/Checkout/StripePaymentInput'
import { mapActions, mapGetters } from 'vuex'
import config from '../../config'
import helper from '../../helper'
import Etn from '../../components/Payment/Etn'
import GiftCard from '../../components/Payment/GiftCard'
import Crypto from '../../components/Payment/Crypto'

export default {
  name: 'CheckoutOrders',
  components: { ThreeStepBreadcrumbs, StripePaymentInput, Etn, GiftCard, Crypto },
  data () {
    return {
      settings: JSON.parse(sessionStorage.getItem('settings')),
      loadGiftCard: false,
      loadETN: false,
      loadETNCom: false,
      loadCrypto: false,
      errorSwitchETN: false,
      errorMessageETN: true,
      loadDummyPayment: false,
      selectPaymentHash: 'stripe',
      activePaymentHash: 'stripe',
      GiftCard: '',
      showGiftApplied: false,
      showGiftCardLowFunds: false,
      userBalance: 0.00,
      dateExpired: '',
      errorMessageGiftCard: '',
      errorSwitchGiftCard: false,
      disabledGiftCard: false,
      applyingGiftCard: false,
      showGiftRemoved: false,
      totalPaymentsOptions: 1,
      showPage: false,
      buttonDisabled: false,
      breadcrumbs: [],
      paymentOptions: [],
      NameInput: '',
      Email: '',
      base: false,
      taskPrice: 0.00,
      subTotal: 0.00,
      serviceFee: 0.00,
      serviceFeeRate: 0.00,
      total: 0.00,
      etn_price: 0.00,
      showEtn: false,
      defaultFee: 0,
      errorMessage: '',
      errorSwitch: false,
      complete: false,
      loadStripe: false,
      paymentAuthHash: '',
      selectedPayment: 'stripe',
      task: [],
      paymentChanged: false,
      paymentFees: {},
      userHasBalance: false,
      stripeOptions: {
        elements: {
          locale: this.languageCheck()
        },
        style: {
          base: {
            color: '#495057',
            fontSize: '15px',
            fontWeight: '400'
          }
        }
      },
      displaySecureMessage: true,
      quantitySelected: 0,
      deliveryTimeString: '',
      jobHash: ''
    }
  },
  mounted () {
    this.stripeSetUp()
    this.getTaskFromBasket()
    this.fetchEmail()
    this.setPaymentMethod(this.selectedPayment)
  },
  methods: {
    ...mapActions([
      'parseJwt',
      'setPaymentMethod',
      'setNewOffers'
    ]),
    getDeliveryTimeString (val) {
      if (val === 1) {
        this.deliveryTimeString = val + ' ' + this.$t('delivery_time_hour')
      } else if (val < 24) {
        this.deliveryTimeString = val + ' ' + this.$t('delivery_time_hours')
      } else if (val === 24) {
        this.deliveryTimeString = '1 ' + this.$t('delivery_time_day')
      } else if (val % 24 === 0) {
        this.deliveryTimeString = (val / 24) + ' ' + this.$t('delivery_time_days')
      } else {
        let days = Math.floor(val / 24)
        let hours = val % 24
        this.deliveryTimeString = days + ' ' + (days === 1 ? this.$t('delivery_time_day') : this.$t('delivery_time_days')) + ' ' + hours + ' ' + (hours === 1 ? this.$t('delivery_time_hour') : this.$t('delivery_time_hours'))
      }
    },
    sendConversionEvent () {
      if (this.$route.params.queryID === 'suggested' && config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'suggestedTaskConversion',
          'ecommerce': {
            'currencyCode': 'USD',
            'products': [{
              'item_name': this.task.title,
              'item_id': this.task.hash,
              'price': this.task.packages[0].price,
              'task_review_count': this.task.review_count,
              'task_review_average': this.task.review_average
            }]
          }
        })
      }
      if (this.getAdvanceSearch().top && this.$route.params.queryID !== 'suggested') {
        window.aa('convertedObjectIDsAfterSearch', {
          eventName: 'Conversion',
          userToken: this.$store.state.user.hash,
          index: config.advanced_search.index_name,
          queryID: this.$route.params.queryID,
          objectIDs: [this.task.hash]
        }, {
          headers: {
            'X-Algolia-Application-Id': this.getAdvanceSearch().app_id,
            'X-Algolia-API-Key': this.getAdvanceSearch().public_key
          }
        })
      }
    },
    applyCrypto () {
      this.paymentChanged = false
      this.selectedPayment = 'crypto'
      this.removeGiftCard('crypto')
      this.displaySecureMessage = false
    },
    applyETN () {
      this.paymentChanged = false
      this.selectedPayment = 'etn'
      this.removeGiftCard('etn')
      this.loadETNCom = true
      this.displaySecureMessage = false
    },
    giftCardOptionSelect () {
      this.displaySecureMessage = true
      this.selectedPayment = 'giftcard'
      this.showEtn = false
      if (this.userHasBalance) {
        this.showGiftRemoved = false
        this.applyGiftCard(this.userBalance)
      } else {
        this.showGiftApplied = false
        this.applyingGiftCard = false
        this.showGiftRemoved = false
      }
    },
    emitName (e) {
      this.NameInput = e
    },
    languageCheck () {
      if (localStorage.getItem('language')) {
        return localStorage.getItem('language')
      } else {
        return config.locale_default
      }
    },
    stripeSetUp () {
      let stripCheck = document.getElementById('js-stripe-v3')
      if (!stripCheck) {
        let stripScript = document.createElement('script')
        stripScript.setAttribute('src', 'https://js.stripe.com/v3/')
        stripScript.setAttribute('id', 'js-stripe-v3')
        document.head.appendChild(stripScript)
      }
    },
    taskPriceMath () {
      this.subTotal = this.taskPrice * this.quantitySelected
      this.serviceFee = this.subTotal * this.serviceFeeRate > 0.5 ? this.subTotal * this.serviceFeeRate : 0.5
      this.total = this.getTotal()
      this.paymentChanged = true
    },
    getImageFromTask () {
      let imgPath = ''
      this.task.assets.some(function (asset) {
        if (asset.type === 2) {
          imgPath = helper.formatCloudinaryUrl(asset.path, 'movie_detail')
        } else {
          imgPath = helper.formatCloudinaryUrl(asset.path, 'task')
        }
        return true
      })
      return imgPath
    },
    getTaskFromBasket () {
      ApiService.shoppingBasketGet(this.$route.params.basket).then(response => {
        this.task = response.data.data[0].gig
        this.taskPrice = response.data.data[0].gig.packages[0].price
        this.defaultFee = response.data.data[0].fee
        this.deliveryTime = response.data.data[0].gig.packages[0].delivery
        this.revisions = response.data.data[0].gig.packages[0].revisions
        this.quantitySelected = response.data.data[0].quantity
        this.task = response.data.data[0].gig
        this.getPaymentOptions()
        this.getDeliveryTimeString(this.deliveryTime)

        if (this.task.job) {
          sessionStorage.setItem('jobHash', this.task.slug.split('-')[0])
          this.jobHash = this.task.slug.split('-')[0]
          this.breadcrumbs = [
            { icon: '1', text: this.$t('order_details_breadcrumbs_step_two') },
            { icon: '2', text: this.$t('order_details_breadcrumbs_step_four') }
          ]
        } else {
          this.breadcrumbs = [
            { icon: '1', text: this.$t('order_details_breadcrumbs_step_two') },
            { icon: '2', text: this.$t('order_details_breadcrumbs_step_three') },
            { icon: '3', text: this.$t('order_details_breadcrumbs_step_four') }
          ]
        }

        this.googleTagManager(2)
      }).catch(() => {
      })
    },
    getPaymentOptions () {
      ApiService.getPaymentOptions().then(response => {
        this.paymentOptions = response.data.data
        this.totalPaymentsOptions = this.paymentOptions.length
        this.paymentOptions.forEach(function (paymentOption) {
          if (paymentOption.hash === 'stripe' && paymentOption.display) {
            this.paymentFees[paymentOption.hash] = (paymentOption.fee !== null ? paymentOption.fee : this.defaultFee) / 100
            this.serviceFeeRate = this.paymentFees['stripe']
            this.taskPriceMath()
            this.paymentSetting(paymentOption.hash)
          } else if (paymentOption.hash === 'dummy' && paymentOption.display) {
            this.paymentFees[paymentOption.hash] = (paymentOption.fee !== null ? paymentOption.fee : this.defaultFee) / 100
            this.loadDummyPayment = true
          } else if (paymentOption.hash === 'giftcard' && paymentOption.display) {
            this.paymentFees[paymentOption.hash] = (paymentOption.fee !== null ? paymentOption.fee : this.defaultFee) / 100
            this.applyingGiftCard = true
            this.getUserBalance()
          } else if (paymentOption.hash === 'etn' && paymentOption.display) {
            this.paymentFees[paymentOption.hash] = (paymentOption.fee !== null ? paymentOption.fee : this.defaultFee) / 100
            this.loadETN = true
          } else if (paymentOption.hash === 'crypto' && paymentOption.display) {
            this.paymentFees[paymentOption.hash] = (paymentOption.fee !== null ? paymentOption.fee : this.defaultFee) / 100
            this.loadCrypto = true
          }
        }.bind(this))
      }).catch(() => {
      })
    },
    getUserBalance () {
      ApiService.getUserBalance().then(response => {
        if (response.status === 200) {
          this.updateUserBalance(response.data.data)
          this.applyGiftCard(response.data.data.amount, true)
        } else {
          this.showGiftApplied = false
          this.applyingGiftCard = false
          this.selectedPayment = 'stripe'
        }
        this.loadGiftCard = true
      }).catch(() => {
      })
    },
    updateUserBalance (balance) {
      this.userBalance = balance.amount
      this.dateExpired = this.datetime(balance.date_expired)
      this.userHasBalance = true
    },
    datetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    applyGiftCard (balance, switchPayment = false) {
      this.applyingGiftCard = true
      this.showGiftRemoved = false
      this.errorSwitchGiftCard = false
      if (balance >= this.total) {
        this.serviceFeeRate = this.paymentFees['giftcard']
        this.taskPriceMath()
        this.showGiftApplied = true
        this.disabledGiftCard = true
        this.activePaymentHash = 'giftcard'
        this.selectedPayment = 'giftcard'
        this.selectPaymentHash = 'giftcard'
      } else {
        this.serviceFeeRate = this.paymentFees['stripe']
        this.taskPriceMath()
        this.errorSwitchGiftCard = true
        this.disabledGiftCard = true
        this.errorMessageGiftCard = this.$t('giftcard_error_insufficient')
        this.activePaymentHash = 'stripe'
        this.selectedPayment = switchPayment ? 'stripe' : 'giftcard'
      }
      this.applyingGiftCard = false
      this.showPage = true
    },
    removeGiftCard (hash = 'stripe') {
      this.displaySecureMessage = true
      this.showEtn = this.loadETNCom && hash === 'etn'
      this.activePaymentHash = hash
      this.selectedPayment = hash
      this.serviceFeeRate = this.paymentFees[hash.toLowerCase()]
      this.taskPriceMath()
      this.applyingGiftCard = true
      this.showGiftApplied = false
      this.applyingGiftCard = false
      if (this.userHasBalance) {
        this.showGiftRemoved = true
      }
    },
    paymentSetting (hash) {
      ApiService.paymentSetting(hash).then(response => {
        this.paymentAuthHash = response.data.data.public_api_key
        if (hash === 'stripe') {
          this.paymentAuth(hash)
        }
      }).catch(() => {
      })
    },
    paymentAuth (hash) {
      ApiService.paymentAuth(hash).then(response => {
        this.stripeToken = response.data.data.token
        this.loadStripe = true
        this.showPage = true
      }).catch(() => {
      })
    },
    googleTagManager (stage) {
      if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        let trackingData = this.getTrackingData()
        window.dataLayer = window.dataLayer || []
        if (this.task.job) {
          this.$tracker.tagEvent('eec.checkout', {
            'ecommerce': {
              'actionField': {
                'step': stage,
                'option': 'job'
              },
              'products': [{
                'item_name': this.task.title,
                'item_id': this.task.hash,
                'price': this.task.packages[0].price
              }]
            }
          })
        } else {
          window.dataLayer.push({
            'event': 'eec.checkout',
            'ecommerce': {
              'actionField': {
                'step': stage,
                'option': this.task.custom !== null ? 'custom-quote' : 'purchase'
              },
              'products': [{
                'price': this.task.packages[0].price,
                'item_name': this.task.title,
                'item_id': this.task.hash,
                'category': trackingData['sub_category'],
                'brand': trackingData['brand'],
                'country_iso': trackingData['seller_iso'],
                'member_since': trackingData['seller_date'],
                'task_review_count': trackingData['review_count'],
                'task_review_average': trackingData['review_average'],
                'user_review_count': trackingData['user_review_count'],
                'user_review_average': trackingData['user_review_average'],
                'seller_response': trackingData['seller_response'],
                'seller_acceptance': trackingData['seller_acceptance'],
                'seller_delivery': trackingData['seller_delivery']
              }]
            }
          })
        }
      }
    },
    async pay (hash, data = []) {
      this.errorSwitch = false
      switch (hash) {
        case 'dummy':
          this.buttonDisabled = true
          ApiService.paymentPurchase(hash, {}).then(response => {
            this.nextPage(response.data.data.hash)
          }).catch(() => {
            this.buttonDisabled = false
          })
          break
        case 'giftcard':
          this.handleGiftCardPurchase(hash)
          break
        case 'etn':
          this.handleETNPayment(hash, data)
          break
        case 'crypto':
          this.buttonDisabled = true
          await this.$refs['paymentCrypto'].createPayLink()
          this.buttonDisabled = false
          break
        default:
          if (this.NameInput === '') {
            this.errorMessage = this.$t('card_name_missing')
            this.errorSwitch = true
          } else {
            this.buttonDisabled = true
            handleCardPayment(this.stripeToken, {
              payment_method_data: {
                billing_details: { name: this.NameInput }
              },
              receipt_email: this.fetchEmail()
            }).then(function (data) {
              if (data.error) {
                this.buttonDisabled = false
                this.errorMessage = data.error.message
                this.errorSwitch = true
              } else {
                const paymentData = {
                  'reference': data.paymentIntent.id,
                  'amount': data.paymentIntent.amount
                }
                ApiService.paymentPurchase(hash, paymentData).then(response => {
                  this.nextPage(response.data.data.hash)
                }).catch(() => {
                })
              }
            }.bind(this)
            )
          }
      }
      this.scrollToError()
    },
    scrollToError () {
      if (this.errorSwitch) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else if (this.errorSwitchETN) {
        document.getElementById('etnSection').scrollIntoView({ behavior: 'smooth' })
      }
    },
    ctvPixelTracker (orderId, taskId, price) {
      if (config.tracking_enabled === 'true') {
        let el = document.createElement('img')
        el.src = 'https://tags.w55c.net/rs?id=89c661d83e6d4c1bbba2225471a46f16&t=checkout' +
          '&tx=' + orderId +
          '&sku=' + taskId +
          '&price=' + price
        document.body.append(el)
      }
    },
    fetchEmail () {
      this.parseJwt().then(function (value) {
        return value.email
      })
    },
    getTotal () {
      return (this.subTotal + this.serviceFee) ? (this.subTotal + this.serviceFee) : 0
    },
    handleGiftCardPurchase (hash) {
      this.errorSwitchGiftCard = false
      this.buttonDisabled = true
      ApiService.paymentPurchase(hash, {}).then(response => {
        this.nextPage(response.data.data.hash)
      }).catch((error) => {
        this.errorMessageGiftCard = error.response.data.error_message
        this.buttonDisabled = false
        this.errorSwitchGiftCard = true
        this.disabledGiftCard = false
      })
    },
    handleETNPayment (hash, data) {
      this.errorSwitchETN = false
      this.buttonDisabled = true
      ApiService.paymentPurchase(hash, data).then(response => {
        this.nextPage(response.data.data.hash)
      }).catch((error) => {
        this.errorMessageETN = error.response.data.error_message
        this.buttonDisabled = false
        this.errorSwitchETN = true
      })
    },
    showEtnPrice (etn) {
      this.etn_price = etn
      this.showEtn = true
    },
    getDisplayPrice (price) {
      return helper.getDisplayPrice(price)
    },
    confirmLeave () {
      return this.$swal({
        title: this.$t('feedback.title'),
        showCancelButton: false,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        focusConfirm: false,
        html: '<label for="swal2-feedback-type">' + this.$t('feedback.select.label') + '</label>' +
          '<select class="swal2-select" id="swal2-feedback-type">' +
          ' <option selected value="">' + this.$t('feedback.select.option_null') + '</option>' +
          ' <option value="0">' + this.$t('feedback.select.option_0') + '</option>' +
          ' <option value="1">' + this.$t('feedback.select.option_1') + '</option>' +
          ' <option value="2">' + this.$t('feedback.select.option_2') + '</option>' +
          ' <option value="3">' + this.$t('feedback.select.option_3') + '</option>' +
          ' <option value="4">' + this.$t('feedback.select.option_4') + '</option>' +
          ' <option value="5">' + this.$t('feedback.select.option_5') + '</option>' +
          '</select>' +
          '<p class="mb-0">' + this.$t('feedback.textarea_label') + '</p>' +
          '<textarea class="swal2-textarea" id="swal2-feedback-reason" maxlength="400"></textarea>',
        preConfirm: () => {
          const type = document.getElementById('swal2-feedback-type').value
          if (type !== '') {
            let data = {
              'reason_type': type
            }

            const reason = document.getElementById('swal2-feedback-reason').value
            if (reason) {
              data.reason = reason
            }

            ApiService.postFeedback(1, data)
          }
          return true
        }
      })
    },
    nextPage (orderHash) {
      this.ctvPixelTracker(orderHash, this.task.hash, this.total.toFixed(2))
      this.sendConversionEvent()
      this.setPaymentMethod(this.selectedPayment)
      localStorage.setItem('orderHash', orderHash)

      if (this.task.job) {
        this.$tracker.tagEvent('eec.purchase', {
          'ecommerce': {
            'actionField': {
              'option': 'job'
            },
            'products': [{
              'item_name': this.task.title,
              'item_id': this.task.hash,
              'price': this.task.packages[0].price
            }]
          }
        })
        const hashArr = this.getNewOffers().hashes
        hashArr.splice(hashArr.indexOf(this.jobHash), 1)
        this.setNewOffers({ count: this.getNewOffers().count - 1, hashes: hashArr })
        this.$router.push({ name: 'confirmation' })
      } else {
        this.$router.push({ name: 'order-requirements' })
      }
    }
  },
  computed: {
    ...mapGetters(['getTrackingData', 'getAdvanceSearch', 'getCurrency', 'getUsername', 'getUserHash', 'getNewOffers'])
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'order-requirements' && to.name !== 'confirmation' && to.name !== '404') {
      this.confirmLeave().then((resp) => {
        if (resp.value) {
          next()
        }
      })
      next(false)
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
.usd-billing {
  color: $breadcrumbs-grey;
  font-size: 0.9rem;
}

.checkout-summary {
  position: sticky;
  top: 170px;
}

.gift-card-copy {
  font-weight: 500;
  line-height: 1.2;
}

.payment-options {
  .card-header {
    border-top: 1px solid rgba(0, 0, 0, 0.125);

    &:hover {
      cursor: hand;
    }

    img {
      width: 2rem;
      filter: grayscale(1);
    }
  }

  .card {
    border: 0;
  }

  .payment-active {
    filter: none !important;
  }
}

@include sm-up {
  html[lang="ru"], html[lang="ja"] {
    h4.payment-type {
      font-size: 1.25rem;
    }
  }
}

@include md-up {
  #checkout-summary {
    z-index: 0 !important;
    button.w-100 {
      width: 75% !important;
    }
  }
}

@include lg-up {
  .checkout-summary {
    top: 100px;
  }
  html[lang="ru"], html[lang="ja"] {
    h4.payment-type {
      font-size: 1.5rem;
    }
  }
}
</style>
