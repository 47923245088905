<template>
  <div>
    <div v-if="loaded">
      <div class="col-12 col-lg-9 mx-auto">
        <template v-if="polling.success">
          <div class="mx-auto my-5">
            <b-spinner large label="Large Spinner"></b-spinner>
            <div>{{ $t('etn_payment.processing') }}</div>
          </div>
        </template>
        <template v-else-if="polling.failed !== null">
          <div>{{ polling.failed }}</div>
        </template>
        <template v-else>
          <div class="qr-holder d-flex flex-column flex-md-row justify-content-between">
            <vue-qrcode :width=500 :value="getQrCode" class="mx-auto mb-2 mb-md-0"/>
            <div class="d-flex flex-column justify-content-center align-items-center px-4">
              <p class="mb-2">{{ $t('etn_payment.scan_info_1') }} <a target="_blank" :href="link">{{ $t('etn_payment.scan_info_2') }}</a></p>
            </div>
          </div>
          <div class="alert alert-warning small mt-3 mb-0">
            <i class="fa-solid fa-triangle-exclamation mr-1"></i>{{ $t('etn_payment.counter.part1') }}
            <Counter :minutes="getTimer" :autostart="true" ref="counter" class="d-inline font-weight-bold"/>
            {{ $t('etn_payment.counter.part2') }}
          </div>
        </template>
      </div>
    </div>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading') }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../config'
import ApiService from '../../apiService'
import VueQrcode from 'vue-qrcode'
import Counter from '../../components/Counter'

export default {
  name: 'Etn',
  components: {
    VueQrcode,
    Counter
  },
  data () {
    return {
      hash: 'etn',
      loaded: false,
      vendor: null,
      paymentId: null,
      amount: 0,
      link: null,
      signature: null,
      reAuthInterval: null,
      polling: {
        interval: null,
        success: false,
        failed: null
      }
    }
  },
  mounted () {
    this.getAuth()
    this.reAuthInterval = setInterval(() => {
      this.stopPolling()
      this.getAuth()
    }, parseInt(config.etn_vendor.reauth_time))
  },
  methods: {
    getAuth () {
      ApiService.paymentAuthUpdate(this.hash, { payment_id: this.paymentId }).then((response) => {
        const data = response.data.data
        this.vendor = data.vendor_address
        this.paymentId = data.payment_id
        this.amount = data.etn_amount
        this.link = data.click_link
        this.signature = data.signature
        this.$emit('show-etn-price', this.amount)
        this.loaded = true
        this.startPolling()
        if (this.$refs.counter) {
          this.$refs.counter.restart()
        }
      })
    },
    startPolling () {
      if (this.polling.interval !== null) {
        return
      }

      this.polling.interval = setInterval(function () {
        axios.post(config.etn_vendor.polling_url, {
          'payment_id': this.paymentId,
          'vendor_address': 'etn-it-' + this.vendor
        }, {
          headers: {
            'Content-Type': 'application/json',
            'ETN-SIGNATURE': this.signature
          },
          transformRequest: (data, headers) => {
            delete headers.common['Authorization']
            return JSON.stringify(data)
          }
        }).then((response) => {
          const data = response.data

          if (data.status > 0) {
            if (data.status === 1) {
              if (data.amount.toFixed(2) === this.amount) {
                this.$emit('send-payment', 'etn', {
                  'amount': data.amount.toFixed(2),
                  'payment_id': this.paymentId
                })
              } else {
                this.polling.failed = this.$t('etn_payment.error_funds', { 'payment_id': this.paymentId })
              }
            } else {
              this.polling.failed = data.message
            }
            this.stopReAuth()
            this.stopPolling()
          }
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
        })
      }.bind(this), parseInt(config.etn_vendor.polling_time))
    },
    stopPolling () {
      if (this.polling.interval !== null) {
        clearInterval(this.polling.interval)
        this.polling.interval = null
      }
    },
    stopReAuth () {
      if (this.reAuthInterval !== null) {
        clearInterval(this.reAuthInterval)
        this.reAuthInterval = null
      }
    }
  },
  computed: {
    getQrCode () {
      return 'etn-it-' + this.vendor + '/' + this.paymentId + '/' + this.amount
    },
    getTimer () {
      return (parseInt(config.etn_vendor.reauth_time) / 60000)
    }
  },
  beforeDestroy: function () {
    this.stopReAuth()
    this.stopPolling()
  }
}
</script>

<style lang="scss" scoped>
.qr-holder {
  img {
    object-fit: contain;
    border: 1px solid #ced4da;
    border-radius: 10px;
    background: white;
    width: 12rem;
  }
}
</style>
