 <template>
  <div id="cryptoSection" class="col-12 mx-auto">
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="mt-3">{{ $t('payments.crypto.content.description') }}</h3>
        <p>
          <img class="mt-2 mr-2" alt="Bitcoin" title="BTC" :src="require('@/assets/Crypto/Bitcoin.svg')" style="width: 50px">
          <img class="mt-2 text-dark" alt="xrp" title="XRP" :src="require('@/assets/Crypto/xrp-black.svg')" style="width: 52px; margin-bottom: -3px">
          <img class="mt-2" alt="bnb" title="BNB" :src="require('@/assets/Crypto/bnb_logo_colour.svg')" style="width: 50px; margin-left: 8px;">
        </p>
      </div>
    </div>
    <div v-if="token">
      <p class="font-weight-bold">{{ $t('payments.crypto.content.awaiting') }}</p>
      <p class="small" v-html="$t('payments.crypto.content.redirect_link', { 'link': token })"></p>
    </div>
    <div class="row text-left">
      <div class="col-md-12 justify-content-center" v-if="polling.func === null">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-12">
                <h4 class="text-center">{{ $t('payments.crypto.billing.title') }}</h4>
                <div class="alert alert-danger" v-if="billingError">{{ $t('payments.crypto.billing.form_error') }}</div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-sm">
                <div class="form-outline">
                  <label class="form-label" for="crypto-full-name">{{ $t('full_name') }}</label>
                  <input type="text" id="crypto-full-name" class="form-control" :class="{ 'border-danger': validationFields.full_name }" v-model="billing.full_name"/>
                </div>
              </div>
              <div class="col mt-4 mt-sm-0">
                <div class="form-outline">
                  <label class="form-label">{{ $t('user_first_time_dob') }}</label>
                  <div class="form-row">
                    <div class="col">
                      <input type="text" placeholder="DD" class="form-control" v-model="dob.day" :class="{ 'border-danger': validationFields.dob }">
                    </div>
                    <div class="col">
                      <input type="text" placeholder="MM" class="form-control" v-model="dob.month" :class="{ 'border-danger': validationFields.dob }">
                    </div>
                    <div class="col">
                      <input type="text" placeholder="YYYY" class="form-control" v-model="dob.year" :class="{ 'border-danger': validationFields.dob }">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-outline">
                  <label class="form-label" for="crypto-country">{{ $t('country_text') }}</label>
                  <b-form-select :options="$t('country')" id="crypto-country" v-model="billing.country" :class="{ 'border-danger': validationFields.country }">
                    <template slot="first">
                      <option :value="null">{{ $t('first_time_login_country_select_default') }}</option>
                    </template>
                  </b-form-select>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-outline">
                  <label class="form-label" for="crypto-address">{{ $t('address') }}</label>
                  <input type="text" id="crypto-address" class="form-control" :class="{ 'border-danger': validationFields.address }" v-model="billing.address"/>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div class="form-outline">
                  <label class="form-label" for="crypto-county">{{ $tc('city_or_county', displayWording) }}</label>
                  <input type="text" id="crypto-county" class="form-control" :class="{ 'border-danger': validationFields.city }" v-model="billing.city"/>
                </div>
              </div>
              <div class="col">
                <div class="form-outline">
                  <label class="form-label" for="crypto-postcode">{{ $tc('post_code', displayWording) }}</label>
                  <input type="text" id="crypto-postcode" class="form-control" :class="{ 'border-danger': validationFields.zip_code }" v-model="billing.zip_code"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 justify-content-center" v-else>
        <div class="card">
          <div class="card-body">
            <h4 class="text-center">{{ $t('payments.crypto.billing.title') }}</h4>
            <ul class="mb-0 pb-0 pl-0">
              <li class="d-flex justify-content-between mb-1">{{ $t('full_name') }}: <strong>{{ billing.full_name }}</strong></li>
              <li class="d-flex justify-content-between mb-1">{{ $t('user_first_time_dob') }}: <strong>{{ getDateString() }}</strong></li>
              <li class="d-flex justify-content-between mb-1">{{ $t('country_text') }}: <strong> {{ $t('country.' + billing.country) }}</strong></li>
              <li class="d-flex justify-content-between mb-1">{{ $t('address') }}: <strong>{{ billing.address }}</strong></li>
              <li class="d-flex justify-content-between mb-1">{{ $tc('city_or_county', displayWording) }}: <strong>{{ billing.city }}</strong></li>
              <li class="d-flex justify-content-between mb-1">{{ $tc('post_code', displayWording) }}: <strong>{{ billing.zip_code }}</strong></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import config from '../../config'

export default {
  name: 'Crypto',
  props: {
    shoppingBasketHash: String
  },
  data () {
    return {
      name: this.$options.name.toLowerCase(),
      dob: {
        day: '',
        month: '',
        year: ''
      },
      billing: {
        full_name: '',
        country: '',
        address: '',
        city: '',
        zip_code: ''
      },
      billingError: false,
      validationFields: {
        full_name: false,
        country: false,
        address: false,
        city: false,
        zip_code: false,
        dob: false
      },
      token: null,
      polling: {
        func: null,
        interval: 1000
      }
    }
  },
  mounted () {
    this.getBilling()
  },
  methods: {
    async createPayLink () {
      this.billingError = false
      if (this.validation()) {
        this.billingError = true
        return null
      }
      const response = await ApiService.paymentAuthPost(this.name, { ...this.billing, 'dob': this.getDateString() }).catch((e) => {
        if (e.response.status === 422) {
          this.$swal(this.$t('remove_task_error_title'), e.response.data.error_message, 'error')
        } else {
          this.billingError = true
          document.getElementById('cryptoSection').scrollIntoView({ behavior: 'smooth' })
        }
        return null
      })
      if (response === null) {
        return null
      }
      this.token = response.data.data.token
      window.open(this.token, '_blank')
      this.startPolling()
      return this.token
    },
    validation () {
      let error = false
      for (const property in this.billing) {
        if (this.billing[property].length < 2) {
          this.validationFields[property] = true
          error = true
        } else {
          this.validationFields[property] = false
        }
      }

      const dobString = this.getDateString()
      if (!this.isValidDate(dobString) || this.getAge() < 18) {
        this.validationFields.dob = true
        error = true
      } else {
        this.validationFields.dob = false
      }
      error && document.getElementById('cryptoSection').scrollIntoView({ behavior: 'smooth' })

      return error
    },
    startPolling () {
      if (this.polling.func !== null) {
        return
      }
      this.polling.func = setInterval(() => {
        ApiService.paymentPolling(this.name, { hash: this.shoppingBasketHash }).then((resp) => {
          const data = resp.data.data
          if (data.status) {
            this.$emit('next-page', data.order.hash)
            this.stopPolling()
          }
        })
      }, parseInt(config.payment.crypto.polling_time))
    },
    stopPolling () {
      if (this.polling.func !== null) {
        clearInterval(this.polling.func)
      }
    },
    openPayment () {
      this.$emit('open-payment', this.name)
    },
    getBilling () {
      if (this.$store.getters.getDob() !== null) {
        const dateSplit = this.$store.getters.getDob().split('-')
        this.dob.day = dateSplit[2]
        this.dob.month = dateSplit[1]
        this.dob.year = dateSplit[0]
      }
      ApiService.getBillingDetails().then(response => {
        if (response.data.data !== null) {
          this.billing = {
            full_name: response.data.data.full_name,
            country: response.data.data.country,
            address: response.data.data.address,
            city: response.data.data.city,
            zip_code: response.data.data.zip_code
          }
        }
      }).catch((e) => {
      })
    },
    getAge (dobString) {
      const today = new Date()
      const birthDate = new Date(this.dob.year, this.dob.month, this.dob.day)
      let age = today.getFullYear() - birthDate.getFullYear()
      let m = today.getMonth() - birthDate.getMonth()

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    isValidDate (value) {
      // Assumess is "dd/mm/yyyy"
      if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(value)) {
        return false
      }
      let parts = value.split('/').map((p) => parseInt(p, 10))
      parts[1] -= 1
      const d = new Date(parts[2], parts[1], parts[0])
      return d.getMonth() === parts[1] && d.getDate() === parts[0] && d.getFullYear() === parts[2]
    },
    getDateString () {
      return this.dob.day.padStart(2, '0') + '/' + this.dob.month.padStart(2, '0') + '/' + this.dob.year
    }
  },
  computed: {
    displayWording: () => {
      return navigator.language.toLowerCase() === 'en-gb' ? 1 : 0
    }
  },
  beforeDestroy () {
    this.stopPolling()
  }
}
</script>
